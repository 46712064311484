import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  SpinnerSize,
  Spinner,
  DelayedRender,
  Callout,
  Text,
  TooltipHost,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import moment from "moment-timezone";
import Moment from "react-moment";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { CheckInsClient } from "../../../../infrastructure/api/CheckInsClient";
import { WorkDaysClient } from "../../../../infrastructure/api/WorkDaysClient";
import { ReactComponent as LinkLogo } from "../../../../assets/svg/link-horizontal.svg";
import {
  ReleaseReasonClient,
  ReleaseReasonQueryParams,
} from "../../../../infrastructure/api/ReleaseReasonClient";
import "./AssetsCard.css";
import { ReleaseReasonIds } from "../../../../constants/releaseReasonIds";
export class AssetsCard extends Component {
  _isMounted = false;

  constructor(props) {
    Chart.register(ArcElement, Tooltip, Legend);
    super(props);
    this.state = {
      currentDate: new Date(new Date().toDateString()),
      date: moment(),
      currentUser: null,
      data: {},
      releaseLoadingIndex: null,
      defaultRealeaseId: null,
      selectedRealeaseId: null,
      checkInLoadingIndex: null,
      bookedAssets: [],
      isCalloutVisible: false,
      errorIndex: null,
      showReleaseIndex: null,
      cardHeightWithReason: 70,
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = false;
    this.update();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async update() {
    const { bookedAssets } = this.props;
    if (bookedAssets && bookedAssets.length > 0)
      this.setState({ bookedAssets: bookedAssets });
  }

  componentWillReceiveProps(props) {
    const { bookedAssets, releaseReasons, fromMobileView } = props;

    if (
      bookedAssets &&
      (this.state.bookedAssets.length !== bookedAssets.length ||
        JSON.stringify(this.state.bookedAssets) !==
          JSON.stringify(bookedAssets))
    )
      this.setState({ bookedAssets: bookedAssets });
    else if (!bookedAssets) this.setState({ bookedAssets: [] });

    if (releaseReasons && releaseReasons.length > 0) {
      const addHeight = fromMobileView ? 45 : 35;
      const finalHeight = addHeight * releaseReasons.length;
      this.setState({
        cardHeightWithReason: 70 + finalHeight,
      });

      const defaultReason = releaseReasons.find(
        (reason) => reason.default == true
      );
      if (defaultReason)
        this.setState({
          defaultRealeaseId: defaultReason.id,
          selectedRealeaseId: defaultReason.id,
        });
    }
  }

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      // background:
      //   "linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(214,237,241,1) 85%, rgba(221,244,248,1) 100%)",
      overflow: "hidden",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
    // releaseReasonBtn: [
    //   getFocusStyle(getTheme(), { inset: -1 }),
    //   {
    //     backgroundColor: "#F1F1F2",
    //     color: "gray",
    //     transition: "background-color 0.2s ease",
    //     selectors: {
    //       "&:hover": {
    //         color: "white",
    //         backgroundColor: "#3E97FF", // Change color on hover
    //       },
    //     },
    //   },
    // ],
  });

  render() {
    const { reloadData, key, todaysPlan, fromMobileView, releaseReasons } =
      this.props;
    const { bookedAssets, isCalloutVisible, errorIndex, errorMessage } =
      this.state;

    const parentIds = [...new Set(bookedAssets?.map((x) => x.parentId))].filter(
      (x) => x !== null && x !== undefined
    );

    return (
      <div key={key}>
        {bookedAssets &&
          bookedAssets.length > 0 &&
          bookedAssets.map((bookedAsset, index) => {
            const islinked =
              bookedAsset?.parentId || parentIds.includes(bookedAsset.id);
            return (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  padding: "10px",
                  minWidth: "250px",
                  maxWidth: fromMobileView ? "100%" : "250px",
                  marginTop: "10px",
                  height:
                    this.state.showReleaseIndex === index
                      ? `${this.state.cardHeightWithReason}px`
                      : fromMobileView
                      ? "80px"
                      : "70px",
                  transition: "height 0.3s ease",
                }}
                className={this.classNames.cardShadow}
              >
                <div style={{ display: "flex" }}>
                  <TooltipHost content={bookedAsset?.asset?.name}>
                    <div
                      style={{
                        borderRadius: "5px",
                        background: bookedAsset?.asset?.assetType?.color
                          ? bookedAsset?.asset?.assetType?.color
                          : "#F1416C",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <b style={{ color: "white" }}>
                        {bookedAsset?.asset?.name?.charAt(0)}
                      </b>
                    </div>
                  </TooltipHost>

                  <div
                    style={{
                      marginLeft: "10px",
                      flexGrow: 1,
                      lineHeight: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <b style={{ fontSize: fromMobileView ? "15px" : "14px" }}>
                        {bookedAsset?.asset?.name.length > 10
                          ? `${bookedAsset?.asset?.name.slice(0, 10)}...`
                          : bookedAsset?.asset?.name}
                      </b>
                      <div
                        style={{ fontSize: fromMobileView ? "14px" : "12px" }}
                      >
                        {" "}
                        <Moment utc local format="h:mm A">
                          {moment(bookedAsset.checkInDate)}
                        </Moment>{" "}
                        -{" "}
                        <Moment utc local format="h:mm A">
                          {moment(bookedAsset.checkOutDue)}
                        </Moment>
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: fromMobileView ? "14px" : "12px",
                        display: "flex",
                      }}
                    >
                      {bookedAsset?.asset?.assetType?.name}{" "}
                      {bookedAsset?.asset?.floor &&
                        `- ${bookedAsset?.asset?.floor?.name}`}
                    </div>
                  </div>
                </div>{" "}
                <div
                  style={{
                    // position: "absolute",
                    // bottom: "10px",
                    // right: "5px",
                    width: "100%",
                    display: "flex",
                    justifyContent: islinked ? "space-between" : "end",
                    marginTop: fromMobileView ? "18px" : "15px",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {islinked && (
                    <LinkLogo
                      height="20"
                      width="20"
                      style={{
                        overflow: "visible",
                        alignSelf: "center",
                        marginLeft: "3px",
                      }}
                      fill={"#BFBFBF"}
                      stroke={"#BFBFBF"}
                    />
                  )}

                  <div style={{ display: "flex" }}>
                    {this.state.showReleaseIndex !== index && (
                      <div
                        style={{
                          borderRadius: "5px",
                          background: "#F1F1F2",
                          marginRight: "5px",
                          cursor: "pointer",
                          padding: "3px 5px",
                          fontSize: fromMobileView ? "14px" : "12px",
                          height: fromMobileView ? "25px" : "20px",
                          width: fromMobileView ? "55px" : "50px",
                          textAlign: "center",
                          color: "gray",
                          display: "block",
                        }}
                        onClick={async () => {
                          this.setState({ showReleaseIndex: index });
                        }}
                      >
                        Release
                      </div>
                    )}

                    {this.state.showReleaseIndex === index && (
                      <div
                        style={{
                          borderRadius: "5px",
                          cursor: "pointer",
                          padding: "3px 5px",
                          fontSize: fromMobileView ? "14px" : "12px",
                          height: fromMobileView ? "25px" : "20px",
                          textAlign: "center",
                          color: "gray",
                          backgroundColor: "#F1F1F2",
                          width: fromMobileView ? "55px" : "50px",
                        }}
                        onClick={async () => {
                          this.setState({ showReleaseIndex: null });
                        }}
                      >
                        Cancel
                      </div>
                    )}
                    {bookedAsset?.asset?.assetType?.checkInRequired &&
                      bookedAsset?.status === "pending" &&
                      this.state.showReleaseIndex !== index && (
                        <div
                          id={`btnAssetsCardCheckIn${index}`}
                          style={{
                            borderRadius: "5px",
                            background: "#3E97FF",
                            cursor: "pointer",
                            padding: "3px 5px",
                            textAlign: "center",
                            color: "white",
                            fontSize: fromMobileView ? "14px" : "12px",
                            height: fromMobileView ? "25px" : "20px",
                            width: fromMobileView ? "55px" : "50px",
                            display: "block",
                          }}
                          onClick={async () => {
                            this.setState({ checkInLoadingIndex: index });
                            var response = await CheckInsClient.EarlyCheckIn(
                              bookedAsset.id
                            );

                            if (response.successful) {
                              if (
                                todaysPlan &&
                                todaysPlan?.status !== "confirmed"
                              ) {
                                await WorkDaysClient.ConfirmMyDay(
                                  todaysPlan.id
                                );
                              }

                              reloadData();
                              await new Promise((resolve) =>
                                setTimeout(resolve, 1000)
                              );
                            } else {
                              var errorMessage = response.data.errorMessage;

                              this.setState({
                                isCalloutVisible: true,
                                errorIndex: index,
                                errorMessage,
                              });
                            }
                            this.setState({ checkInLoadingIndex: null });
                          }}
                        >
                          {" "}
                          {this.state.checkInLoadingIndex === index && (
                            <Spinner size={SpinnerSize.small} />
                          )}
                          Check in
                        </div>
                      )}
                  </div>
                  {this.state.showReleaseIndex === index &&
                    releaseReasons.map((reason, reasonIndex) => {
                      const releaseCheckIn = async () => {
                        this.setState({
                          releaseLoadingIndex: reasonIndex,
                          selectedRealeaseId: reason.id,
                        });
                        await CheckInsClient.ReleaseCheckIn(
                          bookedAsset.id,
                          reason.id
                        );
                        reloadData();
                        await new Promise((resolve) =>
                          setTimeout(resolve, 1000)
                        );
                        this.setState({
                          releaseLoadingIndex: null,
                          showReleaseIndex: null,
                          selectedRealeaseId: this.state.defaultRealeaseId,
                        });
                      };
                      return (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              borderRadius: "5px",
                              cursor: "pointer",
                              padding: "3px 5px",
                              fontSize: fromMobileView ? "14px" : "12px",
                              height: fromMobileView ? "25px" : "20px",
                              textAlign: "center",
                              display:
                                this.state.releaseLoadingIndex === reasonIndex
                                  ? "flex"
                                  : "block",
                              justifyContent: "center",
                              color:
                                reason.id === this.state.selectedRealeaseId
                                  ? "white"
                                  : "gray",
                              backgroundColor:
                                reason.id === this.state.selectedRealeaseId
                                  ? "#3E97FF"
                                  : "#F1F1F2",
                            }}
                            className="releaseBtn"
                            onClick={async () => {
                              releaseCheckIn();
                            }}
                          >
                            {this.state.releaseLoadingIndex === reasonIndex && (
                              <Spinner size={SpinnerSize.small} />
                            )}
                            {reason.name}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}

        {isCalloutVisible && (
          <Callout
            target={`#${`btnAssetsCardCheckIn${errorIndex}`}`}
            onDismiss={() => {
              this.setState({ isCalloutVisible: false });
            }}
            style={{ padding: "20px 24px" }}
            role="alert"
          >
            <DelayedRender>
              <Text variant="small">{errorMessage}</Text>
            </DelayedRender>
          </Callout>
        )}
      </div>
    );
  }
}
