import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ImageFit,
  Stack,
  Image,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  DatePicker,
  defaultDatePickerStrings,
  addDays,
  Panel,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  DayOfWeek,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Label,
  Toggle,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import { UsersClient } from "../../infrastructure/api/UsersClient";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import {
  TeamsClient,
  TeamQueryParams,
} from "../../infrastructure/api/TeamsClient";
import { CardDoughnut } from "../../components/Charts/CardDoughnut";
import { ReactComponent as HomeLogo } from "../../assets/svg/home-location-filled.svg";
import { ReactComponent as WhatsOnLogo } from "../../assets/svg/calendar-clock-filled.svg";
import { ReactComponent as CheckInLogo } from "../../assets/svg/ticket.svg";
import { WeeklyPlannerActionSelector } from "../Common/WeeklyPlannerActionSelector/WeeklyPlannerActionSelector";
import {
  NotWorkingReasonClient,
  NotWorkingReasonQueryParams,
} from "../../infrastructure/api/NotWorkingReasonClient";
import {
  AssetsQueryParams,
  AssetsClient,
} from "../../infrastructure/api/AssetsClient";

import {
  SiteQueryParams,
  SitesClient,
} from "../../infrastructure/api/SitesClient";
import { EventsTable } from "../Charts/EventsTable";
import { DayCard } from "../Common/DayCard/DayCard";
import _ from "lodash";
import { TeamMember } from "../Charts/TeamMember";
import moment from "moment-timezone";
import { FloorSelector } from "../Selectors/FloorSelector";
import { AssetTypeColumnSelector } from "../Selectors/AssetTypeColumnSelector";
import { SiteSelector } from "../Selectors/SiteSelector";
import { BookAssetItem } from "../Common/BookAssetItem/BookAssetItem";

import { FloorPlan } from "../Common/FloorPlan/FloorPlan";
import { FloorsClient } from "../../infrastructure/api/FloorsClient";

export class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      currentWeekStartDate: this.getMonday(new Date(new Date().toDateString())),
      isDatePickerOpen: false,
      teams: [],
      selectedTeam: null,
      userSummary: [],
      userSummaryWeekends: [],
      showWeekends: false,
      refreshPlanner: false,
      initialized: false,
      myDay: {
        date: new Date(new Date().toDateString()),
        parts: [
          {
            state: "workFromOffice",
          },
        ],
      },
      sites: [],
      showEvents: false,
      events: [],
      userId: null,
      indexRefreshing: null,
      percentWorking: 0,
      todayOnwardDates: [],
      cardsLoading: false,
      selectMode: false,
      teamMemberIds: [],
      teamMembersLoading: true,
      assets: [],
      column1: [],
      column2: [],
      column3: [],
      column4: [],
      showFloorPlan: false,
      assetTypes: [],
      floor: {},
      showTeamMembers: false,
      showAssetsFilterPanel: false,
    };
  }

  componentDidMount() {
    this.update();
    var selectedFloor = localStorage.getItem("assets_selectedFloor");

    if (selectedFloor !== null) {
      this.setState({ floor: JSON.parse(selectedFloor) });
    }
  }

  async update() {
    const { currentUser } = this.props;
    if (currentUser?.site) {
      this.setState({
        siteId: currentUser?.site?.id,
      });
    }

    if (currentUser?.teamMembers) {
      var members = _.flattenDeep(
        currentUser?.teamMembers.map((x) => x.team.teamMembers)
      );
      this.setState({ teamMemberIds: _.uniq(members.map((x) => x.id)) });
    }
    await this.populateData();
  }

  componentWillReceiveProps(props) {
    const { currentDate, currentWeekStartDate, selectedTeam, currentUser } =
      this.props;
    if (
      this.state.currentDate !== currentDate ||
      this.state.currentWeekStartDate !== currentWeekStartDate
    ) {
      this.setState(
        {
          currentDate: currentDate,
          currentWeekStartDate: currentWeekStartDate,
        },
        () => {
          // this.populateData();
          this.populateAssets();
        }
      );
    }
  }

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      height: "100%",
      // background:
      //   "linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(214,237,241,1) 85%, rgba(221,244,248,1) 100%)",
      overflow: "hidden",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    var stackTokens = {
      childrenGap: 20,
      padding: 20,
    };
    const iconClass = mergeStyles({
      fontSize: 50,
      height: 50,
      width: 50,
      margin: "0 25px",
    });
    const options = [
      { key: "home", text: "Home" },
      { key: "banana", text: "On Site" },
      { key: "grape", text: "Not Working" },
    ];

    const {
      currentDate,
      loading,
      selectedAssetType,
      showFloorPlan,
      column1,
      column2,
      column3,
      column4,
      allDates,
      fullAssetsList,
      showTeamMembers,
      assetsInitialized,
    } = this.props;

    const {} = this.state;

    return (
      <>
        <div className={this.classNames.container}>
          {loading ? (
            <div style={{ width: "100%", display: "flex" }}>
              {selectedAssetType && (
                <div style={{ width: "100%", margin: "20px 0px" }}>
                  <Shimmer style={{ padding: "5px" }} />
                  <Shimmer style={{ padding: "5px" }} width="90%" />
                  <Shimmer style={{ padding: "5px" }} width="80%" />
                  <Shimmer style={{ padding: "5px" }} width="70%" />
                  <Shimmer style={{ padding: "5px" }} width="60%" />
                  <Shimmer style={{ padding: "5px" }} width="50%" />
                  <Shimmer style={{ padding: "5px" }} width="40%" />
                </div>
              )}
            </div>
          ) : (
            <>
              {assetsInitialized && showFloorPlan && selectedAssetType && (
                <FloorPlan
                  floorId={this.props.floor?.id}
                  assets={fullAssetsList}
                  date={currentDate}
                  refreshAssets={() => {
                    this.populateAssets();
                  }}
                  teamMemberIds={this.state.teamMemberIds}
                  showTeamMembers={showTeamMembers}
                />
              )}
              {assetsInitialized && !showFloorPlan && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    padding: "15px 0",
                  }}
                >
                  {selectedAssetType && loading && (
                    <div style={{ width: "100%", margin: "20px 0px" }}>
                      <Shimmer style={{ padding: "5px" }} />
                      <Shimmer style={{ padding: "5px" }} width="90%" />
                      <Shimmer style={{ padding: "5px" }} width="80%" />
                      <Shimmer style={{ padding: "5px" }} width="70%" />
                      <Shimmer style={{ padding: "5px" }} width="60%" />
                      <Shimmer style={{ padding: "5px" }} width="50%" />
                      <Shimmer style={{ padding: "5px" }} width="40%" />
                    </div>
                  )}
                  {!loading &&
                    selectedAssetType &&
                    column1 &&
                    column1.length > 0 && (
                      <div
                        style={{
                          margin: "0 0 0 20px",
                          width: "22%",
                          background: "white",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        {!loading &&
                          column1.length > 0 &&
                          column1.map((item, index) => {
                            var isTeamMember = false;
                            var checkInUserIds = item.checkIns.map(
                              (x) => x.userId
                            );
                            var match = _.intersection(
                              this.state.teamMemberIds,
                              checkInUserIds
                            );

                            if (match && match.length > 0) {
                              isTeamMember = true;
                            }

                            return (
                              <div
                                style={
                                  {
                                    // flexGrow: 1,
                                    // width: "22%",
                                  }
                                }
                              >
                                <BookAssetItem
                                  item={item}
                                  weekDates={allDates}
                                  onDismiss={() => {
                                    this.setState({
                                      showEvents: false,
                                      assets: [],
                                      floor: {},
                                      selectedAssetType: {},
                                    });
                                  }}
                                  date={currentDate}
                                  singleDateMode
                                  onDismissRefresh={(refreshDayOnly) => {
                                    this.populateAssets();
                                  }}
                                  onOpenAsset={(index) => {
                                    this.setState({
                                      assetItemIndexOpened: `${index}`,
                                    });
                                  }}
                                  indexOpened={this.state.assetItemIndexOpened}
                                  index={`${index}-a`}
                                  highLightTeam={
                                    isTeamMember && showTeamMembers
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    )}

                  {!loading &&
                    selectedAssetType &&
                    column2 &&
                    column2.length > 0 && (
                      <div
                        style={{
                          margin: "0 0 0 20px",
                          width: "22%",
                          background: "white",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        {!loading &&
                          column2.length > 0 &&
                          column2.map((item, index) => {
                            var isTeamMember = false;
                            var checkInUserIds = item.checkIns.map(
                              (x) => x.userId
                            );
                            var match = _.intersection(
                              this.state.teamMemberIds,
                              checkInUserIds
                            );

                            if (match && match.length > 0) {
                              isTeamMember = true;
                            }

                            return (
                              <div
                                style={
                                  {
                                    // flexGrow: 1,
                                    // width: "22%",
                                  }
                                }
                              >
                                <BookAssetItem
                                  item={item}
                                  weekDates={allDates}
                                  onDismiss={() => {
                                    this.setState({
                                      showEvents: false,
                                      assets: [],
                                      floor: {},
                                      selectedAssetType: {},
                                    });
                                  }}
                                  date={currentDate}
                                  singleDateMode
                                  onDismissRefresh={(refreshDayOnly) => {
                                    this.populateAssets();
                                  }}
                                  onOpenAsset={(index) => {
                                    this.setState({
                                      assetItemIndexOpened: `${index}`,
                                    });
                                  }}
                                  indexOpened={this.state.assetItemIndexOpened}
                                  index={`${index}-b`}
                                  highLightTeam={
                                    isTeamMember && showTeamMembers
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    )}

                  {!loading &&
                    selectedAssetType &&
                    column3 &&
                    column3.length > 0 && (
                      <div
                        style={{
                          margin: "0 0 0 20px",
                          width: "22%",
                          background: "white",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        {!loading &&
                          column3.length > 0 &&
                          column3.map((item, index) => {
                            var isTeamMember = false;
                            var checkInUserIds = item.checkIns.map(
                              (x) => x.userId
                            );
                            var match = _.intersection(
                              this.state.teamMemberIds,
                              checkInUserIds
                            );

                            if (match && match.length > 0) {
                              isTeamMember = true;
                            }

                            return (
                              <div
                                style={
                                  {
                                    // flexGrow: 1,
                                    // width: "22%",
                                  }
                                }
                              >
                                <BookAssetItem
                                  item={item}
                                  weekDates={allDates}
                                  onDismiss={() => {
                                    this.setState({
                                      showEvents: false,
                                      assets: [],
                                      floor: {},
                                      selectedAssetType: {},
                                    });
                                  }}
                                  date={currentDate}
                                  singleDateMode
                                  onDismissRefresh={(refreshDayOnly) => {
                                    this.populateAssets();
                                  }}
                                  onOpenAsset={(index) => {
                                    this.setState({
                                      assetItemIndexOpened: `${index}`,
                                    });
                                  }}
                                  indexOpened={this.state.assetItemIndexOpened}
                                  index={`${index}-c`}
                                  highLightTeam={
                                    isTeamMember && showTeamMembers
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    )}
                  {!loading &&
                    selectedAssetType &&
                    column4 &&
                    column4.length > 0 && (
                      <div
                        style={{
                          margin: "0 20px 0 20px",
                          width: "22%",
                          background: "white",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        {!loading &&
                          column4.length > 0 &&
                          column4.map((item, index) => {
                            var isTeamMember = false;
                            var checkInUserIds = item.checkIns.map(
                              (x) => x.userId
                            );
                            var match = _.intersection(
                              this.state.teamMemberIds,
                              checkInUserIds
                            );

                            if (match && match.length > 0) {
                              isTeamMember = true;
                            }

                            return (
                              <div
                                style={
                                  {
                                    // flexGrow: 1,
                                    // width: "22%",
                                  }
                                }
                              >
                                <BookAssetItem
                                  item={item}
                                  weekDates={allDates}
                                  onDismiss={() => {
                                    this.setState({
                                      showEvents: false,
                                      assets: [],
                                      floor: {},
                                      selectedAssetType: {},
                                    });
                                  }}
                                  date={currentDate}
                                  singleDateMode
                                  onDismissRefresh={(refreshDayOnly) => {
                                    this.populateAssets();
                                  }}
                                  onOpenAsset={(index) => {
                                    this.setState({
                                      assetItemIndexOpened: `${index}`,
                                    });
                                  }}
                                  indexOpened={this.state.assetItemIndexOpened}
                                  index={`${index}-d`}
                                  highLightTeam={
                                    isTeamMember && showTeamMembers
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    )}
                </div>
              )}
            </>
          )}
        </div>

        {this.renderDatePicker()}
        {this.renderWhatsOnPanel()}
      </>
    );
  }

  async updateAssetTypes() {
    if (this.props.floor?.id) {
      var assetTypesResponse = await FloorsClient.GetAssetTypesFromFloor(
        this.props.floor?.id
      );

      var assetTypes = assetTypesResponse.data;

      this.setState({
        assetTypes: assetTypes,
        floorAssetTypes: assetTypes,
      });
    }
  }

  async populateAssets(softLoad = false) {
    const {
      currentDate,
      date,
      currentUser,
      currentWeekStartDate,
      selectedAssetType,
      reloadAssets,
    } = this.props;

    if (reloadAssets) reloadAssets(true);
    this.updateAssetTypes();
    this.setState({ loading: false });
  }

  renderDatePicker() {
    return (
      <Dialog
        type={DialogType.normal}
        title="Choose date"
        hidden={!this.state.isDatePickerOpen}
        onDismiss={() => this.setState({ isDatePickerOpen: false })}
      >
        <DatePicker
          firstDayOfWeek={DayOfWeek.Monday}
          id="currentDatePicker2"
          minDate={new Date()}
          value={this.state.currentDate}
          onSelectDate={(date) =>
            this.setState(
              {
                currentDate: date,
                currentWeekStartDate: this.getMonday(date),
                isDatePickerOpen: false,
              },
              () => {
                this.populateData();
              }
            )
          }
          placeholder="Select a date..."
          ariaLabel="Select a date"
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
        />

        <DialogFooter>
          <DefaultButton
            onClick={(ev) => {
              this.setState({ isDatePickerOpen: false });
              ev.preventDefault();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  async populateData(softLoad = false, cardsLoading = false) {
    const { currentWeekStartDate } = this.props;
    if (!softLoad) this.setState({ loading: true });
    if (cardsLoading) this.setState({ cardsLoading: true });

    const { currentUser } = this.props;

    if (currentUser && currentUser.site) {
      var siteId = currentUser.site.id;

      this.setState({
        siteId: siteId,
        userId: currentUser.id,
        user: currentUser,
        selectedSite: currentUser?.site,
      });

      if (currentUser.teams && currentUser.teams.length > 0) {
        var teamId = currentUser.teams[0].id;

        if (!this.state.selectedTeam) {
          this.setState({
            teams: currentUser.teams,
            selectedTeam: {
              id: currentUser.teams[0].id,
              name: currentUser.teams[0].name,
            },
          });
        }

        this.setState({
          initComplete: true,
          loading: false,
          indexRefreshing: null,
          cardsLoading: false,
        });
      } else {
        this.setState({
          loading: false,
          initialized: true,
          indexRefreshing: null,
          selectMode: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        initialized: true,
        indexRefreshing: null,
        selectMode: false,
      });
    }
  }
  forwardOneWeek() {
    var newDate = addDays(this.state.currentDate, 7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
        },
        () => {
          this.populateData();
        }
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
        },
        () => {
          this.populateData();
        }
      ); //If other week, default to Monday
    }
  }

  backwardOneWeek() {
    var newDate = addDays(this.state.currentDate, -7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
        },
        () => {
          this.populateData();
        }
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
        },
        () => {
          this.populateData();
        }
      ); //If other week, default to Monday
    }
  }

  getMyDayForDate(data, date) {
    var myDay = {
      date: this.state.currentDate,
      status: "planned",
      parts: [
        {
          state: "workFromOffice",
        },
      ],
    };

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() == date.getTime()) {
          if (data[i].users.length > 0 && data[i].users[0].myDay) {
            myDay = data[i].users[0].myDay;
          }
        }
      }
    }

    return myDay;
  }

  getChartForDate(data, date) {
    var chart = {};

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() == date.getTime()) {
          if (data[i].users.length > 0 && data[i].users[0].myDay) {
            chart = data[i].pieChart;
          }
        }
      }
    }

    return chart;
  }

  getTodayOnwardsDates(data) {
    var dates = [];
    var today = new Date(new Date().toDateString());

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() >= today.getTime()) {
          dates.push(data[i].date);
        }
      }
    }

    return dates;
  }

  renderWhatsOnPanel() {
    return (
      <Panel
        isLightDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showEvents}
        onDismiss={() => this.setState({ showEvents: false })}
        closeButtonAriaLabel="Close"
        headerText="Events"
        type={3}
      >
        <EventsTable
          events={this.state.events}
          userId={this.state.userId}
          refreshEvents={() => {
            this.populateEvents();
          }}
        />
      </Panel>
    );
  }
}
