import React, { Component } from "react";
import { Stack, Panel, Shimmer } from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import moment from "moment";
import { ActivityFeedClient } from "../../infrastructure/api/ActivityFeedsClient";
import {
  EventsQueryParams,
  EventsClient,
} from "../../infrastructure/api/EventsClient";
import { MessagesClient } from "../../infrastructure/api/MessagesClient";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import {
  NotWorkingReasonClient,
  NotWorkingReasonQueryParams,
} from "../../infrastructure/api/NotWorkingReasonClient";
import {
  SiteQueryParams,
  SitesClient,
} from "../../infrastructure/api/SitesClient";
import { ActivityFeeds } from "../Charts/ActivityFeeds";

import { ReactComponent as ChevronLogo } from "../../assets/svg/angle-small-right.svg";
import { ReactComponent as BellLogo } from "../../assets/svg/bell.svg";
import { ReactComponent as BellsLogo } from "../../assets/svg/bells.svg";
import { ReactComponent as NoticeLogo } from "../../assets/svg/memo.svg";
import { ReactComponent as AssetLogo } from "../../assets/svg/grid-2.svg";
import { ReactComponent as UnseenLogo } from "../../assets/svg/envelope-dot.svg";
import { ReactComponent as DeleteLogo } from "../../assets/svg/delete.svg";
import { ReactComponent as RefreshLogo } from "../../assets/svg/refresh.svg";
import { ReactComponent as EmailLogo } from "../../assets/svg/envelope.svg";
import { ReactComponent as PhoneLogo } from "../../assets/svg/phone-flip.svg";
import { ReactComponent as PinLogo } from "../../assets/svg/marker.svg";
import { ReactComponent as CalendarLogo } from "../../assets/svg/calendar.svg";
import { UserAttributeSelector } from "../Selectors/UserAttributeSelector";
import { SiteSelector } from "../Selectors/SiteSelector";
import { PulseCard } from "../Common/PulseCard/PulseCard";
import { EventsList } from "../Charts/EventsList";
import Moment from "react-moment";
import { getDayLocalizationLabel } from "../../helpers/localizationHelper";
import { AssetsCard } from "../Modules/Assets/AssetsCard/AssetsCard";
import { SiteStatChart } from "../Modules/Dashboard/SiteStatChart";
import { generateGUID } from "../../infrastructure/helpers/stringHelper";
import { BookAssetButton } from "../Common/BookAssetButton/BookAssetButton";
import { NoticeClient } from "../../infrastructure/api/NoticeClient";
import { NoticeModal } from "../Common/WorkPlanSiteDropdown/NoticeModal";
import { OtherNoticeModal } from "../Common/WorkPlanSiteDropdown/OtherNoticeModal";
import { WhatsOnButton } from "../Common/WhatsOnButton/WhatsOnButton";
import { TeamMemberDashboard } from "../Common/TeamMemberDashboard/TeamMemberDashboard";
import {
  ReleaseReasonClient,
  ReleaseReasonQueryParams,
} from "../../infrastructure/api/ReleaseReasonClient";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      currentWeekStartDate: this.getMonday(new Date(new Date().toDateString())),
      userAttributes: [],
      showPanel: false,
      filteredUsers: [],
      percentWorking: 0,
      notWorkingReasons: [],
      sites: [],
      selectedTeam: {},
      messages: [],
      messagesLoading: true,
      activityFeeds: [],
      selectedFeed: null,
      feedIndex: null,
      bookedAssets: [],
      refreshPulseCard: false,
      assetsKey: generateGUID(),
      todaysPlan: null,
      otherNotices: [],
      otherNoticesLoading: false,
      showNoticeModal: false,
      events: [],
      teamMembers: [],
      releaseReasons: [],
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
    await this.refreshActivityFeeds();
    await this.refreshOtherNotices();
    await this.populateTeamMembers();
  }

  componentWillReceiveProps(props) {
    const { selectedTeam, currentDate } = props;

    if (this.state.currentDate !== currentDate) {
      this.setState({ currentDate: currentDate }, () => {
        this.refreshEvents();
        this.populateData(true, true);
        this.populateTeamMembers();
      });
    }

    if (this.state.user?.id !== this.props.currentUser?.id)
      this.refreshMessages();

    if (selectedTeam?.id !== this.state.selectedTeam?.id) {
      this.setState(
        {
          selectedTeam: selectedTeam,
        },
        () => {
          this.populateData(false);
          this.populateTeamMembers();
        }
      );
    }
  }

  async populateTeamMembers() {
    const { currentDate } = this.state;
    const { selectedTeam } = this.props;
    this.setState({ teamMembersLoading: true }, async () => {
      if (selectedTeam?.id) {
        var teamSummary = await HomeClient.TeamMemberView(
          new HomeQueryParams()
            .WithTeamId(selectedTeam.id)
            .WithNumberOfDays(1)
            .WithParam("startDate", currentDate)
        );

        this.setState({
          teamMembersLoading: false,
          teamMembers: teamSummary.data,
        });
      } else
        this.setState({
          teamMembersLoading: false,
        });
    });
  }

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      overflow: "hidden",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        transition: "0.3s",
        margin: "10px 0px",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)",
          },
        },
      },
    ],
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    var stackTokens = {
      childrenGap: 20,
      padding: 20,
    };
    const iconClass = mergeStyles({
      fontSize: 50,
      height: 50,
      width: 50,
      margin: "0 25px",
    });
    var onSiteColor = localStorage.getItem("theme_OnSite");

    return (
      <>
        {this.state.loading ? (
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <Shimmer style={{ padding: "5px" }} />
              <Shimmer style={{ padding: "5px" }} width="90%" />
              <Shimmer style={{ padding: "5px" }} width="80%" />
              <Shimmer style={{ padding: "5px" }} width="70%" />
              <Shimmer style={{ padding: "5px" }} width="60%" />
              <Shimmer style={{ padding: "5px" }} width="50%" />
              <Shimmer style={{ padding: "5px" }} width="40%" />
            </div>
          </div>
        ) : (
          <div className={this.classNames.container}>
            {!this.props.isMobile && (
              <div
                style={{
                  margin: "50px 20px 0px 20px",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "17px",
                    width: "560px",
                  }}
                >
                  {getDayLocalizationLabel(
                    moment(this.state.currentDate).format("dddd")
                  )}
                  , {this.state.percentWorking}% of your team are planning to be
                  on site!
                </div>
                {this.state.messages && this.state.messages.length > 0 && (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "10px",
                      height: "100px",
                      overflow: "scroll",
                      maxWidth: "420px",
                      minWidth: "420px",
                    }}
                    className={this.classNames.cardShadow}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <b
                          style={{
                            fontWeight: 600,
                            fontSize: 13,
                          }}
                        >
                          Messages
                        </b>{" "}
                      </div>
                      {_.find(this.state.messages, function (o) {
                        return o.activityStatus === "seen";
                      }) ? (
                        <div style={{ display: "flex", alignItems: "start" }}>
                          <div
                            style={{
                              cursor: "pointer",
                              marginRight: "5px",
                            }}
                            onClick={async () => {
                              this.setState({ messagesLoading: true });
                              await MessagesClient.ClearSeenMessages(
                                this.props.currentUser.id
                              );
                              this.refreshMessages();
                            }}
                          >
                            <DeleteLogo
                              height="18"
                              width="18"
                              fill="#bfbfbf"
                              stroke="#bfbfbf"
                            />
                          </div>
                          <div
                            onClick={() => {
                              this.refreshMessages();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <RefreshLogo height="15" width="15" />
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            this.refreshMessages();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <RefreshLogo height="15" width="15" />
                        </div>
                      )}
                    </div>
                    <div style={{ padding: "5px" }} onMouseOver={() => {}}>
                      {this.state.messagesLoading && (
                        <div style={{ width: "100%", display: "flex" }}>
                          <div style={{ width: "100%", margin: "20px 0px" }}>
                            <Shimmer style={{ padding: "5px" }} />
                            <Shimmer style={{ padding: "5px" }} width="90%" />
                            <Shimmer style={{ padding: "5px" }} width="80%" />
                            <Shimmer style={{ padding: "5px" }} width="70%" />
                            <Shimmer style={{ padding: "5px" }} width="60%" />
                            <Shimmer style={{ padding: "5px" }} width="50%" />
                            <Shimmer style={{ padding: "5px" }} width="40%" />
                          </div>
                        </div>
                      )}
                      {this.state.messages &&
                        !this.state.messagesLoading &&
                        this.state.messages.map((message) => (
                          <div
                            className="card-item"
                            style={{ marginBottom: "5px" }}
                          >
                            <div
                              className="container"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                cursor:
                                  message.activityStatus === "unseen"
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() => {
                                if (message.activityStatus === "unseen") {
                                  const idx = this.state.messages.findIndex(
                                    (obj) => obj.id == message.id
                                  );

                                  let copyArray = this.state.messages;

                                  copyArray[idx].activityStatus = "seen";

                                  this.setState({ messages: copyArray });

                                  MessagesClient.SetMessageSeen(message.id);
                                }
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {message.title}
                                </div>
                                <span style={{ color: "rgb(99 99 99)" }}>
                                  {message.content}
                                </span>
                                <div>
                                  <i
                                    style={{
                                      color: "rgb(99 99 99)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <Moment
                                      utc
                                      local
                                      format="ddd DD MMM, YYYY h:mm a"
                                    >
                                      {message.dateSent}
                                    </Moment>
                                  </i>
                                </div>
                              </div>
                              <div>
                                {message.activityStatus === "unseen" && (
                                  <UnseenLogo height="15" width="15" />
                                )}{" "}
                                {message.activityStatus === "seen" && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                    onClick={async () => {
                                      const idx = this.state.messages.findIndex(
                                        (obj) => obj.id == message.id
                                      );
                                      let copyArray = this.state.messages;
                                      copyArray[idx].activityStatus = "cleared";
                                      this.setState({
                                        messages: copyArray,
                                        messagesLoading: true,
                                      });
                                      await MessagesClient.Update(
                                        message.id,
                                        "cleared",
                                        message?.seen
                                      );
                                      this.refreshMessages();
                                    }}
                                  >
                                    <DeleteLogo
                                      height="15"
                                      width="15"
                                      fill="#bfbfbf"
                                      stroke="#bfbfbf"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}{" "}
                      {this.state.messages.length === 0 &&
                        !this.state.messagesLoading && (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "15px",
                              color: "#f1f1f1",
                            }}
                          >
                            <div>
                              <EmailLogo
                                width="40"
                                height="40"
                                stroke="#f1f1f1"
                                fill="#f1f1f1"
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              style={{
                marginTop: this.state.messages.length === 0 ? "50px" : "0px",
              }}
            >
              <Stack
                horizontal
                tokens={stackTokens}
                horizontalAlign={"start"}
                verticalFill
                verticalAlign={"start"}
                wrap
              >
                {!this.state.loading && this.props.currentUser && (
                  <PulseCard
                    day={this.state.todaysChart}
                    selectedTeam={this.props.selectedTeam}
                    sites={this.state.sites}
                    user={this.props.currentUser}
                    notWorkingReasons={this.state.notWorkingReasons}
                    percentWorking={this.state.percentWorking}
                    currentWeekStartDate={this.getMonday(
                      this.props.currentDate
                    )}
                    currentDate={this.props.currentDate}
                    refreshPlanner={() => {
                      this.populateData(false, false);
                      this.populateTeamMembers();
                    }}
                    cardsLoading={this.state.loading || this.state.pulseLoading}
                    refreshPulseCard={this.state.refreshPulseCard}
                    onChangeSite={(id) => {
                      this.loadSiteChartData(id);
                      this.populateData(false, false);
                    }}
                  />
                )}
                <div
                  style={{
                    minWidth: "270px",
                    maxWidth: "270px",
                  }}
                >
                  <SiteStatChart
                    data={this.state.siteChartdata}
                    currentDate={this.props.currentDate}
                  />
                  {this.state.otherNotices &&
                    this.state.otherNotices.length > 0 && (
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "5px",
                          padding: "10px",
                          height: "20px",
                          minWidth: "250px",
                          maxWidth: "250px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                          marginBottom: "10px",
                          cursor: "pointer",
                        }}
                        className={this.classNames.buttonShadow}
                        onClick={() => {
                          this.setState({ showNoticeModal: true });
                        }}
                      >
                        <b style={{ color: "#FA5B55" }}>Click to review</b>
                        <NoticeLogo
                          fill={onSiteColor}
                          stroke={onSiteColor}
                          height="20"
                          width="20"
                        />
                      </div>
                    )}

                  {this.state.events && this.state.events.length > 0 && (
                    <WhatsOnButton
                      selectedTeam={this.state.selectedTeam}
                      date={this.props.currentDate}
                      userId={this.props.user?.id}
                      isOnDashboard
                    />
                  )}

                  <BookAssetButton
                    site={
                      this.state.todaysChart?.users[0]?.myDay?.parts[0]?.site
                    }
                    date={this.state.todaysChart?.date}
                    currentWeekStartDate={this.getMonday(
                      this.props.currentDate
                    )}
                    refreshPlanner={() => {
                      this.populateData(false, false);
                    }}
                    refreshDay={() => {
                      this.populateData(false, false);
                    }}
                    labelComponent={
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: "5px",
                          padding: "10px",
                          height: "20px",
                          minWidth: "250px",
                          maxWidth: "250px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                        }}
                        className={this.classNames.buttonShadow}
                      >
                        <b>Booked Assets</b>
                        <AssetLogo
                          fill={onSiteColor}
                          stroke={onSiteColor}
                          height="20"
                          width="20"
                        />
                      </div>
                    }
                  />
                  <AssetsCard
                    todaysPlan={this.state.todaysPlan}
                    bookedAssets={this.state.bookedAssets}
                    reloadData={() => {
                      this.populateData(false, true);
                      this.setState({
                        refreshPulseCard: !this.state.refreshPulseCard,
                      });
                    }}
                    key={this.state.assetsKey}
                    releaseReasons={this.state.releaseReasons}
                  />
                </div>

                {this.state.teamMembers &&
                  this.state.teamMembers.length > 0 && (
                    <div>
                      {this.state.teamMembers.map((teamMember) => (
                        <TeamMemberDashboard
                          teamMember={teamMember}
                        ></TeamMemberDashboard>
                      ))}
                    </div>
                  )}

                {/* <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    padding: "10px",
                    height: "450px",
                    overflow: "scroll",
                    maxWidth: "420px",
                    minWidth: "420px",
                  }}
                  className={this.classNames.cardShadow}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 5px",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 13,
                      }}
                    >
                      What's On ({this.state.events.length})
                    </p>
                    <CalendarLogo
                      height="18"
                      width="18"
                      style={{ overflow: "visible", marginRight: "5px" }}
                    />
                  </div>
                  {this.state.events.length === 0 && (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "50px",
                        color: "#f1f1f1",
                      }}
                    >
                      <div>
                        <CalendarLogo
                          width="60"
                          height="60"
                          stroke="#f1f1f1"
                          fill="#f1f1f1"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.events.length > 0 && (
                    <EventsList
                      events={this.state.events}
                      currentDate={this.props.currentDate}
                      userId={this.props.currentUser?.id}
                      refreshEvents={() => {
                        this.refreshEvents();
                      }}
                    />
                  )}
                </div> */}
              </Stack>
            </div>
          </div>
        )}
        {this.renderNoticeModal()}
        {this.renderWhatsOnPanel()}
      </>
    );
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  async populateStaticData() {
    var notWorkingReasons = await NotWorkingReasonClient.List(
      new NotWorkingReasonQueryParams()
    );

    var sites = await SitesClient.List(
      new SiteQueryParams().WithStatus("active")
    );

    var releaseReasons = await ReleaseReasonClient.List(
      new ReleaseReasonQueryParams()
    );

    this.setState({
      sites: sites.data.data,
      notWorkingReasons: notWorkingReasons.data.data,
      releaseReasons: releaseReasons.data.data,
    });
    this.refreshEvents();
  }

  async refreshEvents() {
    var eventParams = new EventsQueryParams();
    if (this.state.selectedTeam && this.state.selectedTeam?.id)
      eventParams.WithTeamIds([this.state.selectedTeam.id]);

    var start = this.state.currentDate;
    eventParams.WithStart(start);
    eventParams.WithEnabled(true);
    eventParams.Sort("startDate", "asc");
    // eventParams.WithEnd(moment(start).add(1, "d").toISOString());

    var events = await EventsClient.List(eventParams);
    this.setState({
      events: events.data.data,
    });
  }

  async refreshMessages() {
    this.setState({ messagesLoading: true }, async () => {
      const { currentUser } = this.props;
      var messages = await MessagesClient.GetUserMessages(currentUser.id);

      this.setState({
        messages: messages.data,
        messagesLoading: false,
      });
    });
  }

  async refreshActivityFeeds() {
    this.setState({ activityFeedsLoading: true }, async () => {
      var data = await ActivityFeedClient.GetListForUser();

      this.setState({
        activityFeeds: data.data,
        selectedFeed: data.data && data.data?.length ? data.data[0] : null,
        feedIndex: data.data && data.data?.length ? 0 : null,
        activityFeedsLoading: false,
      });
    });
  }

  async refreshOtherNotices() {
    this.setState({ otherNoticesLoading: true }, async () => {
      const userId = localStorage.getItem("userId");
      var data = await NoticeClient.GetUnviewedNotices(userId);

      this.setState({
        otherNotices: data.data,
        otherNoticesLoading: false,
      });
    });
  }

  async loadSiteChartData(siteId) {
    var chartData = await SitesClient.GetDailySummary(
      siteId,
      moment(this.props.currentDate).format("YYYY-MM-DD")
    );
    this.setState({
      siteChartdata: chartData.data,
    });
  }

  async populateData(reloadStaticData = true, softReload = false) {
    const { currentUser, selectedTeam } = this.props;

    if (!softReload) this.setState({ pulseLoading: true });

    if (reloadStaticData) {
      this.setState({ loading: true, pulseLoading: true });
      await this.populateStaticData();
    }
    if (currentUser && currentUser.site) {
      if (reloadStaticData) {
        await this.refreshMessages();
        await this.refreshActivityFeeds();
      }
      var siteId = currentUser.site.id;

      this.setState({
        siteId: siteId,
        userId: currentUser.id,
        user: currentUser,
        selectedSite: currentUser?.site,
      });

      if (currentUser.teams && currentUser.teams.length > 0) {
        var team = selectedTeam ? selectedTeam : currentUser.teams[0];

        if (!this.state.selectedTeam) {
          this.setState({
            teams: currentUser.teams,
            selectedTeam: {
              id: team.id,
              name: team.name,
            },
          });
        }

        //Get currentUser MyDay
        var userSummary = await HomeClient.DayView(
          new HomeQueryParams()
            .WithSiteId(siteId)
            .WithTeamId(team.id)
            .WithUserId(currentUser.id)
            .WithNumberOfDays(1)
            .WithParam("startDate", this.props.currentDate)
        );

        var bookedAssets = userSummary.data[0]?.users[0]?.myDay?.checkIns;
        var todaysPlan = userSummary.data[0]?.users[0]?.myDay;

        var chartSiteId = todaysPlan?.parts[0]?.siteId
          ? todaysPlan.parts[0]?.siteId
          : siteId;
        await this.loadSiteChartData(chartSiteId);

        this.setState(
          {
            todaysChart: userSummary.data[0],
            initComplete: true,
            loading: false,
            indexRefreshing: null,
            cardsLoading: false,
            pulseLoading: false,
            bookedAssets: bookedAssets,
            assetsKey: generateGUID(),
            todaysPlan: todaysPlan,
            refreshPulseCard: !this.state.refreshPulseCard,
          },
          () => {
            const { todaysChart } = this.state;

            if (todaysChart) {
              var pie = todaysChart.pieChart;
              var percentWorking =
                pie.workingOnSite > 0
                  ? Math.round((pie.workingOnSite / pie.total) * 100)
                  : 0;

              this.setState({ percentWorking: percentWorking });
            }
          }
        );
      }
    }

    this.setState({ loading: false });
  }

  filterUsers() {
    if (this.state.siteId && this.state.selectedAttribute.users.length > 0) {
      var filtered = _.filter(this.state.selectedAttribute.users, (user) => {
        return (
          user?.site?.id === this.state.siteId ||
          (user?.myDay &&
            user?.myDay?.parts.length &&
            user?.myDay?.parts[0]?.site?.id === this.state.siteId)
        );
      });

      var sorted = _.sortBy(filtered, [
        function (user) {
          return user?.myDay ? -1 : 1;
        },
      ]);
      // var filtered = this.state.selectedAttribute.users.filter(
      //   (user) =>
      //     (this.state.siteId && user?.site?.id === this.state.siteId) ||
      //     (user?.myDay &&
      //       user?.myDay?.parts[0].state === "workFromOffice" &&
      //       user?.myDay?.parts[0].site?.id === this.state.siteId)
      // );

      this.setState({ filteredUsers: sorted });
    } else if (
      this.state.siteId &&
      this.state.selectedAttribute.users.length <= 0
    ) {
      this.setState({ filteredUsers: [] });
    }
  }

  renderNoticeModal() {
    return (
      <OtherNoticeModal
        notices={this.state.otherNotices}
        showModal={this.state.showNoticeModal}
        onClose={(reload) => {
          this.refreshOtherNotices();
          this.setState({ showNoticeModal: false });
        }}
      />
    );
  }

  renderWhatsOnPanel() {
    const { currentUser } = this.props;

    return (
      <Panel
        isLightDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showPanel}
        onDismiss={() => this.setState({ showPanel: false })}
        closeButtonAriaLabel="Close"
        headerText="Today"
        type={1}
      >
        <div style={{ marginTop: "10px" }}>
          <UserAttributeSelector
            onSelect={(attribute) => {
              const { site } = currentUser;

              this.setState({ selectedAttribute: attribute }, () => {
                this.filterUsers();
              });
            }}
            selectedAttribute={this.state.selectedAttribute}
            siteId={currentUser?.site?.id}
            hideLabel
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <SiteSelector
            onSelect={async (item) => {
              this.setState({ selectedSite: item, siteId: item.id }, () => {
                this.filterUsers();
                // this.populateAssets();
              });
            }}
            setOptions={async (sites) => {
              this.setState({ siteOptions: sites }, () => {
                this.filterUsers();
                // this.populateData();
                // this.populateAssets();
              });
            }}
            siteId={currentUser?.site?.id}
            hideLabel
          />
        </div>
        {this.state.filteredUsers &&
          this.state.filteredUsers.map((user) => {
            var state = "";

            if (user?.myDay) {
              state = user?.myDay?.parts[0].state;
            }
            return (
              <div
                className={this.classNames.itemCell + " hover"}
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  padding: "10px",
                  marginTop: "10px",
                  fontSize: "12px",
                  border: user.myDay
                    ? `2px solid ${this.state.selectedAttribute.color}`
                    : "",
                }}
              >
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    marginBottom: "3px",
                  }}
                >
                  {user.name} {user.surname}
                </div>
                <div
                  style={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "3px",
                  }}
                >
                  <EmailLogo height="15" width="15" />
                  <div style={{ marginLeft: "5px" }}>{user.email}</div>
                </div>

                <div
                  style={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "3px",
                  }}
                >
                  <PhoneLogo height="15" width="15" />
                  <div style={{ marginLeft: "5px" }}>
                    {user.mobile ? user.mobile : "-"}
                  </div>
                </div>

                <div
                  style={{
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "3px",
                  }}
                >
                  <PinLogo height="15" width="15" />
                  {user?.myDay && state === "workFromOffice" && (
                    <div style={{ marginLeft: "5px" }}>
                      {user?.myDay?.parts[0].site?.name}
                    </div>
                  )}
                  {user?.myDay && state === "notWorking" && (
                    <div style={{ marginLeft: "5px" }}>
                      {user?.myDay?.parts[0].notWorkingReason?.name}
                    </div>
                  )}
                  {user?.myDay && state === "workFromHome" && (
                    <div style={{ marginLeft: "5px" }}>Home</div>
                  )}
                  {!user?.myDay && (
                    <div style={{ marginLeft: "5px" }}>Unknown</div>
                  )}
                </div>
              </div>
            );
          })}
      </Panel>
    );
  }
}
